import React from "react";
import {Link}  from "gatsby"

import { GetURL } from "./functions";


const GenerateLink =(props,{children})=> {
	let url = ''
	if (props.link) {
		url = GetURL(props.link.id)
	}
    return(
		<React.Fragment>
			{url.indexOf("http://") == 0 || url.indexOf("https://") == 0 ?
    		<a href={`${url}`} target="_blank" className={props.class}>{props.children}</a>
			: url ? <Link to={`/${url}/`} className={props.class}>{props.children}</Link> : <a href="javascript:void(0);" className={props.class}>{props.children}</a>}
		</React.Fragment>
    )
}

export default GenerateLink;

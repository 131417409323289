import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import GenerateLink from "../common/site/generate-link"
import $ from 'jquery'
import { navigate } from "@reach/router"
const MegaMenu = (props) => {

  let url = "/";

  useEffect(() => {
    // $(".search-btn").click(function () {
    //   var searcValue = $(".address").val()
    //   url = "/property/for-sale/"
    //   if (searcValue !== "") {
    //     url += "in-" + searcValue + "/"
    //   } else {
    //     url += "in-kent/"
    //   }
    //   navigate(url);
    // })
  })

  return (
    <div className="menu-dropdown">
      {props.data?.In_Submenu_Show_Search &&
        <div className="postcode_form">
          <div className="h3">{props.data?.Submenu_Search_Title}</div>
          <form>

            {/* <SearchLoqateAddress val={props.val} /> */}

            <Button variant="primary" className="search-btn" onClick={()=>{navigate('/vacancy-type');}}>Vacancies<i className="icon-arrow"></i></Button>
            <Button variant="primary" className="search-btn" onClick={()=>{navigate('/contact');}}>Get in Touch <i className="icon-arrow"></i></Button>
          </form>
        </div>
      }
      <div className="dropdown-link-list">
        {props.data && props.data.Add_Sub_Menus.map((item, i) => (
          <div className="dropdown-box">
            {item.Title &&
              <span>{item.Title}</span>
            }
            <ul>
              {item.Add_here.map((menus, i) => (
                <li>
                  <GenerateLink link={menus.Submenu_Link}>{menus.Submenu_Label}</GenerateLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MegaMenu;
